import { Image } from '@graphcommerce/image'
import svgLogoPurple from './logo-purple.svg'
import svgLogoWhite from './logo-white.svg'

export function Logo({ mode = 'purple' }: { mode?: 'white' | 'purple' }) {
  return (
    <div className='w-24'>
      <Image alt='Templi Logo' src={mode === 'purple' ? svgLogoPurple : svgLogoWhite} unoptimized />
    </div>
  )
}
