import { useCartQuery } from '@graphcommerce/magento-cart'
import { useCustomerSession } from '@graphcommerce/magento-customer'
import { GetCartEmailDocument } from '@graphcommerce/magento-newsletter/components/SignupNewsletter/GetCartEmail.gql'
import { extendableComponent } from '@graphcommerce/next-ui'
import { Box, SxProps, Theme, Typography } from '@mui/material'
import { CustomerNewsletterToggle } from './CustomerNewsletterToggle'
import { GuestNewsletterToggle } from './GuestNewsletterToggle'

type SignupNewsletterProps = { sx?: SxProps<Theme> }

const name = 'SignupNewsletter' as const

type OwnerState = { loggedIn: boolean }
const parts = ['signup', 'text', 'signupForm'] as const
const { withState } = extendableComponent<OwnerState, typeof name, typeof parts>(name, parts)

export function SignupNewsletter(props: SignupNewsletterProps) {
  const { sx = [] } = props
  const { data: cartData } = useCartQuery(GetCartEmailDocument, { allowUrl: true })
  const { loggedIn } = useCustomerSession()

  const classes = withState({ loggedIn })

  return (
    <Box className={classes.signup} sx={[(theme) => ({}), ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        className={classes.signupForm}
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacings.sm,
          justifySelf: 'start',
          alignItems: 'center',
          padding: 0,
          [theme.breakpoints.up('sm')]: { justifySelf: 'end' },
        })}
      >
        {/* {loggedIn ? (
          <CustomerNewsletterToggle sx={{ padding: 0, margin: 0 }} />
        ) : (
          <GuestNewsletterToggle color='primary' sx={{ padding: 0, margin: 0 }} />
        )} */}
        {/* Logged in customer email subscription doesn't work, using guest subscription workflow for now */}
        <GuestNewsletterToggle color='primary' sx={{ padding: 0, margin: 0 }} />
      </Box>
    </Box>
  )
}
