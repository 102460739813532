import { Image } from '@graphcommerce/image'
import { NoSsr } from '@mui/material'
import Link from 'next/link'
import amex from '../../assets/images/amex.svg'
import discover from '../../assets/images/discover.svg'
import background from '../../assets/images/footer-background.png'
import instagram from '../../assets/images/instagram.svg'
import madeInNY from '../../assets/images/made-in-NY.svg'
import mastercard from '../../assets/images/mastercard.svg'
import pinterest from '../../assets/images/pinterest.svg'
import tel from '../../assets/images/telephone.svg'
import templiQR from '../../assets/images/templi-qr.svg'
import tiktok from '../../assets/images/tiktok.svg'
import visa from '../../assets/images/visa.svg'
import { FooterQueryFragment } from '../Layout/FooterQueryFragment.gql'
import logoWhite from '../Layout/logo-white.svg'
import { SignupNewsletter } from '../Newsletter/SignupNewsletter'

type Social = {
  // Use any to avoid conflicts with @svgr/webpack plugin or babel-plugin-inline-react-svg plugin.
  // eslint-disable-next-line
  image: any
  href: string
  alt: string
  isMobile: boolean
}

export function FooterResponsive(props: FooterQueryFragment) {
  const { footer } = props
  const socials: Social[] = [
    {
      image: instagram,
      href: 'https://www.instagram.com/templi/',
      alt: 'instagram',
      isMobile: true,
    },
    {
      image: tiktok,
      href: 'https://www.tiktok.com/@templi_packaging',
      alt: 'tiktok',
      isMobile: true,
    },
    {
      image: pinterest,
      href: 'https://ro.pinterest.com/templii/',
      alt: 'pinterest',
      isMobile: true,
    },
  ]

  return (
    <footer className='bg-templi-purple px-[35px] pb-[6px] pt-[24px] text-pure-white max-[400px]:px-[28px] lg:flex lg:p-0'>
      <div className='mb-[18px] flex items-center justify-between lg:relative lg:mb-0 lg:block lg:grow lg:self-stretch'>
        <div
          className='footerImage hidden h-full min-h-full w-full min-w-full bg-right lg:block lg:bg-cover lg:bg-no-repeat 2xl:bg-contain 2xl:bg-left'
          style={{ backgroundImage: `url(${background.src})` }}
        >
          <Link href='/' legacyBehavior className='flex pl-12 pt-7'>
            <div className='pl-8 pt-5'>
              <Image
                src={logoWhite}
                alt='Templi Logo'
                unoptimized
                layout='fixed'
                className='h-[54px] w-[173px]'
              />
            </div>
          </Link>
        </div>
        <Link href='/' className='block h-[34px] w-[106px] lg:hidden'>
          <Image src={logoWhite} alt='Templi Logo' unoptimized className='h-full w-full' />
        </Link>
        <div className='flex gap-x-7 lg:hidden'>
          {socials.map(
            (social) =>
              social.isMobile && (
                <a href={social.href} key={social.href}>
                  <Image src={social.image} alt={social.alt} unoptimized />
                </a>
              ),
          )}
        </div>
      </div>
      <div className='text-concept-print-white grow-[0.5] lg:pb-[27px]  lg:pt-[48px]'>
        <div className='max-[400px] mb-[17px] flex justify-around lg:mb-[45px] lg:items-start lg:gap-x-10'>
          <div>
            <p className='Type-XXL-Bold lg:Type-XXXL-Bold mb-3.5 text-pure-white lg:mb-4'>
              Resources
            </p>
            <ul>
              {footer?.resources?.map((resource) => (
                <li key={resource.label} className='mb-[8px]'>
                  <span className='Type-Large-Medium lg:Type-XL-Medium hover:text-pure-white-hover hover:underline'>
                    <Link href={resource.url}>{resource.label}</Link>
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className='Type-XXL-Bold lg:Type-XXXL-Bold mb-3.5 text-pure-white lg:mb-4'>
              Top Products
            </p>
            <ul>
              {footer?.topProducts?.map((product) => (
                <li key={product.label} className='mb-[8px]'>
                  <span className='Type-Large-Medium lg:Type-XL-Medium hover:text-pure-white-hover hover:underline'>
                    <Link href={product.url}>{product.label}</Link>
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className='flex h-full flex-col items-center justify-between gap-[35px]'>
            <div className='epadding-[5px] flex hidden w-full flex-col items-center justify-center rounded-[5px] border-[1px] border-pure-white md:block'>
              <span className='Type-XXXL-Bold flex w-full max-w-[300px] items-center justify-center border-b-[1px] bg-pure-white py-[5px] text-templi-purple'>
                Contact us
              </span>
              <div className='p-[10px]'>
                <span className='mb-[10px] grid grid-cols-[20fr_80fr] items-center justify-between'>
                  <span className='material-symbols-outlined mr-[5px]'>mail</span>
                  <span className='Type-XL-Medium ml-[2px]'>
                    <a href='mailto:contact@templi.com'>contact@templi.com</a>
                  </span>
                </span>
                <span className='grid grid-cols-[20fr_80fr] items-center justify-center'>
                  <span className='material-symbols-outlined mr-[5px]'>call</span>
                  <img src={tel.src} className='w-[50%]' alt='telephone number' />
                </span>
              </div>
            </div>
            <div
              className='hidden h-full w-[300px] flex-col items-center justify-start md:flex'
              id='bbbLogoParent'
            >
              <a
                href='https://www.bbb.org/us/ny/brooklyn/profile/packaging-service/templi-inc-0121-87160134/#sealclick'
                target='_blank'
                rel='nofollow noreferrer'
              >
                <img
                  src='https://seal-newyork.bbb.org/seals/gray-seal-280-80-bbb-87160134.png'
                  className='border-0'
                  alt='Templi, Inc. BBB Business Review'
                />
                <div id='bbbLogo' />
                <div className='mt-[15px] flex flex-col gap-[10px]'>
                  <span className='Type-Large-Medium clear-both text-pure-white'>
                    BBB Rating A+
                  </span>
                  <span className='Type-Large-Medium clear-both text-pure-white'>
                    Business ID: 87160134
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className='hidden w-fit text-center lg:block'>
          {/* fix no control error */}
          {/* eslint-disable */}
          <label htmlFor='newsletter' className='Type-XL-Medium mb-[8px]'>
            Join us and learn tips and tricks. No Spam! Just Smiles!
          </label>
          <div className='flex w-fit items-center gap-x-7 rounded bg-iris px-8 py-4 text-tight-black'>
            <NoSsr fallback={null}>
              <SignupNewsletter />
            </NoSsr>
          </div>
        </div>
      </div>
      <div className='my-2 flex grow-[0.5] flex-col items-center self-center lg:mr-[40px]'>
        <ul className='mb-16 hidden gap-x-3 lg:flex'>
          {socials.map((social, i) => (
            <li key={social.href}>
              <a href={social.href} target='_blank'>
                <Image src={social.image} alt='instagram' unoptimized />
              </a>
            </li>
          ))}
        </ul>
        <p className='mb-8 hidden h-[78px] w-[78px] lg:block'>
          <Image src={madeInNY} alt='Made in NY' unoptimized />
        </p>
        <p className='mb-[34px] hidden h-[151px] w-[151px] lg:block'>
          <Image src={templiQR} alt='Made in NY' unoptimized />
        </p>
        <p className='Type-Base-Medium mb-[17px] hidden text-pure-white lg:block'>
          &copy;Templi 2023. All rights reserved.
        </p>
        <ul className='mb-2 flex justify-center gap-x-5 lg:mb-2.5 lg:flex lg:gap-x-3'>
          <li>
            <Image src={visa} alt='Visa' unoptimized />
          </li>
          <li>
            <Image src={amex} alt='Amex' unoptimized />
          </li>
          <li>
            <Image src={discover} alt='Discover' unoptimized />
          </li>
          <li>
            <Image src={mastercard} alt='Mastercard' unoptimized />
          </li>
        </ul>
        <p className='Type-Small-Medium text-center lg:hidden'>
          &copy;Templi 2023. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
