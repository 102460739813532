import { useCartQuery } from '@graphcommerce/magento-cart'
import { ApolloCustomerErrorAlert } from '@graphcommerce/magento-customer'
import {
  GuestNewsletterToggleDocument,
  GuestNewsletterToggleMutation,
  GuestNewsletterToggleMutationVariables,
} from '@graphcommerce/magento-newsletter/components/GuestNewsletterToggle/GuestNewsletterToggle.gql'
import { GetCartEmailDocument } from '@graphcommerce/magento-newsletter/components/SignupNewsletter/GetCartEmail.gql'
import { Form } from '@graphcommerce/next-ui'
import { Controller, useFormAutoSubmit, useFormGqlMutation } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
  SwitchProps,
  SxProps,
  Theme,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { AlertPrompt } from '../Prompt/AlertPrompt'
import { ConfirmationPrompt } from '../Prompt/ConfirmationPrompt'
import { Spinner } from '../Spinner'

export type GuestNewsletterToggleProps = SwitchProps & { sx?: SxProps<Theme> }

export function GuestNewsletterToggle(props: GuestNewsletterToggleProps) {
  const { sx = [], ...switchProps } = props

  const email =
    useCartQuery(GetCartEmailDocument, { allowUrl: true }).data?.cart?.email ?? undefined
  const form = useFormGqlMutation<
    GuestNewsletterToggleMutation,
    GuestNewsletterToggleMutationVariables & { isSubscribed?: boolean }
  >(GuestNewsletterToggleDocument, { mode: 'onChange' })

  const { handleSubmit, control, formState, error, register } = form
  const submit = handleSubmit(() => {})
  useFormAutoSubmit({ form, submit })
  const switchElement = useRef()
  const [userInputEmail, setUserInputEmail] = useState<string>()
  const [displayPrompt, setDisplayPrompt] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setDisplayPrompt(true)
    }
  }, [formState.isSubmitSuccessful])

  useEffect(() => {
    if (error) {
      setChecked(false)
    }
  }, [error])

  // eslint-disable-next-line react/no-unstable-nested-components
  const SignUpButton = ({ onChange, disabled, loading = false, ...rest }) => (
    <button
      type='button'
      className={`Type-XXL-Bold color-tight-black rounded px-6 py-3 hover:bg-marigold-hover ${
        disabled ? 'bg-marigold-hover' : 'bg-marigold'
      }`}
      onClick={() => {
        onChange((switchElement.current as unknown as HTMLInputElement).checked)
        setChecked(true)
      }}
      disabled={disabled}
      {...rest}
    >
      <span className='inline-flex items-center'>
        {formState.isSubmitted ? i18n._('Signed up') : i18n._('Sign me up')}
        {loading ? <Spinner className='ml-[10px]' color='purple' /> : ''}
      </span>
    </button>
  )

  if (formState.isSubmitted)
    return (
      <div className='grid grid-cols-[2fr_1fr] gap-x-5'>
        <input
          id='email'
          placeholder='example@email.com'
          className='h-[47px] rounded px-[18px] outline-none'
          value={userInputEmail}
          disabled
        />
        <AlertPrompt
          title={i18n._('Newsletter Sign up')}
          message={i18n._('Thank you for signing up for our newsletter!')}
          isDisplayed={displayPrompt}
          setIsDisplayed={setDisplayPrompt}
        />
        <SignUpButton onChange={() => {}} disabled />
      </div>
    )
  return (
    <Form noValidate sx={sx}>
      <Controller
        name='isSubscribed'
        control={control}
        render={({ field: { onChange, value, name: controlName, ref, onBlur } }) => (
          <FormControl error={!!formState.errors.isSubscribed}>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              label=''
              control={<Switch inputRef={switchElement} className='hidden' checked={checked} />}
              checked={value}
              inputRef={ref}
              onBlur={onBlur}
              name={controlName}
              onChange={(e) => onChange((e as React.ChangeEvent<HTMLInputElement>).target.checked)}
              id='footerSignUpSwitch'
            />
            {formState.errors.isSubscribed?.message && (
              <FormHelperText>{formState.errors.isSubscribed?.message}</FormHelperText>
            )}
            <div className='grid grid-cols-[2fr_1fr] gap-x-5'>
              <input
                id='email'
                placeholder={email ?? 'example@email.com'}
                className='h-[47px] rounded px-[18px] outline-none'
                value={userInputEmail}
                {...register('email')}
                onChange={(e) => setUserInputEmail(e?.target.value)}
                disabled={!!error || formState.isSubmitting || formState.isSubmitSuccessful}
              />
              <SignUpButton
                onChange={onChange}
                disabled={!!error || formState.isSubmitting || formState.isSubmitSuccessful}
                loading={formState.isSubmitting && !formState.isSubmitSuccessful && !error}
              />
            </div>
          </FormControl>
        )}
      />
      <ApolloCustomerErrorAlert error={error} />
    </Form>
  )
}
